import Vue from 'vue'
import VueRouter from 'vue-router';
import App from './App.vue'
import {routes} from '@/router/index.js'
import {util} from "@/common/util.js";
import {_CONFIG} from "@/config/config.js";

import { Form,Button,Radio,Select,Option,Input,Cascader,Dialog,FormItem,Pagination,RadioGroup,Switch,Message,MessageBox,Loading} from 'element-ui';
const ElMessage = Message
const ElMessageBox = MessageBox

Vue.use(Form);
Vue.use(Button);
Vue.use(Radio);
Vue.use(RadioGroup);
Vue.use(Select);
Vue.use(Option);
Vue.use(Input);
Vue.use(Cascader);
Vue.use(Dialog);
Vue.use(FormItem);
Vue.use(Switch);
Vue.use(Pagination);
Vue.use(Loading);
Vue.prototype.$msgbox = MessageBox;

import './css/style.dev.scss';

//TODO:这一段是处理点击同一个路由报错的额问题
const originalPush = VueRouter.prototype.push
VueRouter.prototype.push = function push(location) {
  return originalPush.call(this, location).catch(err => err)
}

Vue.use(VueRouter);
const router = new VueRouter({
  routes:routes,
  scrollBehavior () {
    return { x: 0, y: 0 }
  }
});

// 路由拦截
const blacklist = ['/my','/my/question','/my/course','/my/live','/my/files']
router.beforeEach((to, from, next) => {
  //util.log('beforeEach',to, from,next);
  if(blacklist.indexOf(to.path) > -1){
    if(!util.getLocalStorage('TOKEN')||!util.getLocalStorage('userInfo')){
      localStorage.clear()
      next({path:'/'})
    }else{
      next()
    }
  }else {
    next()
  }
});

router.afterEach((to, from, next) => {
  //util.log('afterEach',to, from,next);
  if (to.meta.title) {
    document.title = to.meta.title==="首页" ?_CONFIG.siteName : `${to.meta.title}-${_CONFIG.siteName}`
  }
})

Vue.prototype.$message = ElMessage
Vue.prototype.$messageBox = ElMessageBox

Vue.prototype.$GLOBAL = {

}

new Vue({
  mode: 'hash',
  router:router,
  render: h => h(App),
}).$mount('#app')
